import {
  RentevariantOptions,
  Financieringsoort,
  AflossingsVormType,
  IngPriceOutput,
  PeriodiekeAflossingInput,
  RentepercentageInput,
  RentepercentageOutput,
  BetalingsTermijnType,
  RentepercentageResultaat,
  Hypotheekoptie
} from "../../.generated/forms/formstypes";
import {
  RentevariantMetRenteperiodes,
  RentevariantMetRenteperiodesOutput
} from "../../.generated/hypotheekrentes/hypotheekrentestypes";
import { HypotheeklabelDetailsOutput, HypotheekvormBasis } from "../../.generated/producten/productentypes";
import {
  PeriodiekBedragInputTermijn,
  PeriodiekeOnttrekkingInputTermijn,
  VermogensrekeningInput,
  VermogensrekeningInputSoortOpbouwBerekening,
  WaardePerMaand
} from "../../.generated/vermogen/vermogentypes";
import { partijOnafhankelijk } from "../../producten-overzicht/infra/product-constanten";
import { HypothekenKenmerken, KenmerkenError } from "../../producten-overzicht/infra/product-kenmerken-types";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import { jaarMaandInMaanden } from "../../shared/generic-parts/jaar-maand/map-ui-2-dl";
import { Return } from "../../shared/hooks/use-map";
import { AanvragerKeuze } from "../../shared/types";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { afronden } from "../../shared/utils/currency";
import { partijcodeING, WaardeopbouwMaandType } from "../../vermogen/infra/vermogen-types";
import { mapIngPriceToolInput } from "../hypotheek-opties-ing/map-ing-price-tool";
import { hypothekenBaseSchema } from "./hypotheek-schema";
import { HypotheekType, HypothekenState, SoortOnderpand, HypotheekOptieType } from "./hypotheek-types";
import { hasValue, mapLocalDateToString } from "adviesbox-shared";
import { getHypotheekTextResources } from "./hypotheek-resources";
import { ISWFetchDataParams } from "../../shared/components/isw-side-effects/isw-types";

export type AsyncContext = {
  selected: number;
  berekenState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  situatie: SituatieSoort;
  voorstelId: string;
  ingHdnData: [string, string][];
  kenmerken: HypothekenKenmerken;
  kenmerkenMapActions: Return<string, HypothekenKenmerken | KenmerkenError>;
  hypotheekvormen: { [index: string]: HypotheekvormBasis };
  hypotheekDetailData: HypotheeklabelDetailsOutput | null;
};

export type indexedFetchCall<T> = {
  call: Promise<T>;
  index: number;
};

export const getSoortOnderpand = (prod: HypotheekType): SoortOnderpand => {
  return prod.soortOnderpand === Financieringsoort.AankoopBestaandeBouw
    ? SoortOnderpand.BestaandeBouw
    : prod.soortOnderpand === Financieringsoort.AankoopNieuwbouw
    ? SoortOnderpand.Nieuwbouw
    : SoortOnderpand.Geen;
};

export const getRenteboxCode = (
  hypotheekvormen: { [index: string]: HypotheekvormBasis } | null,
  product: HypotheekType
): number | null => {
  const hypotheekvorm = hypotheekvormen
    ? Object.values(hypotheekvormen).find(
        vorm =>
          `${vorm.aflossingsvorm}` === `${product.hypotheekVorm.aflossingsvorm}` &&
          `${vorm.code}` === `${product.hypotheekVorm.code}`
      )
    : /* istanbul ignore next */ null;
  return hypotheekvorm?.renteboxCode ?? null;
};

export const getRentevariantenMetRentePeriodes = async <T>(
  hypotheekRentesOrigin: string,
  producten: HypotheekType[],
  marktwaardePercentage: number,
  nhg: boolean,
  hypotheekvormen: { [index: string]: HypotheekvormBasis },
  fetchData: <R, B = undefined>(params: ISWFetchDataParams<T, B>) => Promise<R>
): Promise<{ [index: number]: RentevariantMetRenteperiodes[] }> => {
  const renteCalls: indexedFetchCall<RentevariantMetRenteperiodesOutput>[] = [];

  producten.forEach((product, index) => {
    const geldverstrekkerCode = product.partijCode;
    const hypotheeklabelCode = product.labelCode;
    const soortOnderpand = getSoortOnderpand(product);
    const renteboxCode = getRenteboxCode(hypotheekvormen, product);
    if (!renteboxCode) return;

    const url = `${hypotheekRentesOrigin}/Geldverstrekkers/${geldverstrekkerCode}/Hypotheeklabels/${hypotheeklabelCode}/Hypotheekvormen/${renteboxCode}/Rentevarianten?soortOnderpand=${soortOnderpand}&marktwaardePercentage=${marktwaardePercentage}&nhg=${nhg}`;

    renteCalls.push({
      call: fetchData({ url, method: "GET" }),
      index: index
    });
  });
  const fetchedRentevarianten = await Promise.all(
    renteCalls.map(async rv => {
      return { index: rv.index, result: await rv.call };
    })
  );
  const rentevarianten: { [index: number]: RentevariantMetRenteperiodes[] } = {};
  fetchedRentevarianten.forEach(rv => {
    if (rv.result.isValid && !!rv.result.rentevarianten) {
      rentevarianten[rv.index] = rv.result.rentevarianten;
    }
  });
  return rentevarianten;
};

export const getHypotheekrentes = async <T>(
  klantdossiersFormsOrigin: string,
  producten: HypotheekType[],
  hypotheekOpties: Hypotheekoptie[],
  voorstelId: string,
  hypotheekvormen: { [index: string]: HypotheekvormBasis },
  fetchData: <R, B = undefined>(params: ISWFetchDataParams<T, B>) => Promise<R>
): Promise<{ [index: number]: RentepercentageResultaat }> => {
  const voorstelLeningdeelRenteCalls: indexedFetchCall<RentepercentageOutput>[] = [];

  producten.forEach((product, index) => {
    const renteboxCode = getRenteboxCode(hypotheekvormen, product);
    if (
      product.partijCode === partijOnafhankelijk ||
      product.productCode === partijcodeING ||
      !renteboxCode ||
      !product.leningdeelgegevens.rentePercentage?.berekenen
    ) {
      return;
    }
    const urlLeningdeelRente = klantdossiersFormsOrigin + "/Voorstellen/" + voorstelId + "/Hypotheek/Hypotheekrente";
    if (product.labelCode && product.hypotheekVorm.code && product.leningdeelgegevens.rentevastPeriodeJaar) {
      const input: RentepercentageInput = {
        aflossingsVorm: AflossingsVormType[product.hypotheekVorm.aflossingsvorm],
        hypotheeklabelCode: product.labelCode,
        hypotheekopties: hypotheekOpties,
        hypotheekvormCode: renteboxCode,
        maatschappijCode: product.renteBoxMaatschappijCode || product.partijCode,
        personeelskortingPercentage: null,
        rentebedenktijdInMaanden: (product.leningdeelgegevens.renteBedenktijdJaar || 0) * 12,
        rentevariant: product.leningdeelgegevens.renteVariant,
        rentevastAantalMaanden: Number(product.leningdeelgegevens.rentevastPeriodeJaar) * 12
      };
      voorstelLeningdeelRenteCalls.push({
        call: fetchData({ url: urlLeningdeelRente, method: "POST", body: JSON.stringify(input) }),
        index: index
      });
    }
  });
  const fetchedVoorstelRentes = await Promise.all(
    voorstelLeningdeelRenteCalls.map(async rc => {
      return { index: rc.index, result: await rc.call };
    })
  );
  const result: { [index: number]: RentepercentageResultaat } = {};
  fetchedVoorstelRentes.forEach(vr => {
    /* istanbul ignore else */
    if (vr.result.isValid && !!vr.result.resultaat) {
      result[vr.index] = vr.result.resultaat;
    }
  });
  return result;
};

export const berekenWaardeopbouwBedrag = (waardeopbouwPerMaand: WaardePerMaand[] | null): number | null => {
  /* istanbul ignore else */
  if (waardeopbouwPerMaand && waardeopbouwPerMaand.length) {
    return waardeopbouwPerMaand[waardeopbouwPerMaand.length - 1].waarde;
  }
  return null;
};

export const berekenWaardeopbouwMaanden = (waardeopbouwPerMaand: WaardePerMaand[] | null): WaardeopbouwMaandType[] => {
  return (
    waardeopbouwPerMaand?.map(waardeopbouw => ({
      maand: waardeopbouw.maand ?? 0,
      eindwaardeBedrag: waardeopbouw.waarde ?? 0
    })) ?? []
  );
};

export function cleanFiscaleRegeling(productkenmerken: HypothekenKenmerken, product: HypotheekType): void {
  if (!productkenmerken.fiscaleRegeling.fiscaleRegelingTonen) {
    product.fiscaleRegeling = null;
  } else if (product.fiscaleRegeling) {
    if (!productkenmerken.fiscaleRegeling.kapitaalopbouwBoxKeuzeTonen) {
      product.fiscaleRegeling.kapitaalopbouw = null;
    }
    if (!productkenmerken.fiscaleRegeling.lijfrenteclausuleTonen) {
      product.fiscaleRegeling.lijfrenteclausuleOrigineel = null;
    }
  }
}

export function cleanKapitaalopbouw(productkenmerken: HypothekenKenmerken, product: HypotheekType): void {
  if (!productkenmerken.kapitaalopbouw.kapitaalopbouwTonen) {
    product.kapitaalopbouw = null;
  } else if (product.kapitaalopbouw) {
    if (!productkenmerken.kapitaalopbouw.doelkapitaalEnabled) {
      product.kapitaalopbouw.doelkapitaal1Bedrag = null;
      product.kapitaalopbouw.doelkapitaal1Overnemen = null;
      product.kapitaalopbouw.doelkapitaal2Bedrag = null;
      product.kapitaalopbouw.doelkapitaal2Overnemen = null;
    }
    if (!productkenmerken.kapitaalopbouw.rekenrendementEnabled) {
      product.kapitaalopbouw.doelkapitaal1Percentage = null;
      product.kapitaalopbouw.doelkapitaal2Percentage = null;
    }
  }
}

export function cleanPremiegegevens(productkenmerken: HypothekenKenmerken, product: HypotheekType): void {
  if (product.premieGegevens) {
    if (!productkenmerken.premie.aanvangstortingEnabled) {
      product.premieGegevens.aanvangExtraPremieStortingenBedrag = null;
    }
    if (!productkenmerken.premie.duurHoogLaagConstructieEnabled) {
      product.premieGegevens.hoogLaagEinddatum = null;
      product.premieGegevens.hoogLaagLooptijd = null;
      product.premieGegevens.hoogLaagVerhouding = null;
      product.premieGegevens.spaarPremieHoog = null;
      product.premieGegevens.risicoPremieHoog = null;
      product.premieGegevens.totalePremieHoog = null;
    }
    if (!productkenmerken.premie.extraStortingTonen) {
      product.premieGegevens.aanvangExtraPremieStortingen = { scenario: [] };
    }
    if (
      (!productkenmerken.premie.heeftBetalingstermijnHalfJaar &&
        product.premieGegevens.betalingstermijn === BetalingsTermijnType.HalfJaar) ||
      (!productkenmerken.premie.heeftBetalingstermijnJaar &&
        product.premieGegevens.betalingstermijn === BetalingsTermijnType.Jaar) ||
      (!productkenmerken.premie.heeftBetalingstermijnKoopsom &&
        product.premieGegevens.betalingstermijn === BetalingsTermijnType.Eenmalig) ||
      (!productkenmerken.premie.heeftBetalingstermijnKwartaal &&
        product.premieGegevens.betalingstermijn === BetalingsTermijnType.Kwartaal) ||
      (!productkenmerken.premie.heeftBetalingstermijnMaand &&
        product.premieGegevens.betalingstermijn === BetalingsTermijnType.Maand) ||
      (!productkenmerken.premie.heeftBetalingstermijnTweeMaanden &&
        product.premieGegevens.betalingstermijn === BetalingsTermijnType.TweeMaanden)
    ) {
      product.premieGegevens.betalingstermijn = BetalingsTermijnType.Geen;
    }
    if (!productkenmerken.premie.premieHoogEnabled) {
      product.premieGegevens.spaarPremieHoog = null;
      product.premieGegevens.risicoPremieHoog = null;
      product.premieGegevens.totalePremieHoog = null;
    }
    if (!productkenmerken.premie.risicopremieEnabled) {
      product.premieGegevens.risicoPremieHoog = null;
      product.premieGegevens.risicoPremieLaag = null;
    }
  }
}

export const bepaalRenteperiodeMetRentepercentage = (
  product: HypotheekType,
  rentevarianten: RentevariantMetRenteperiodes[]
): void => {
  const renteAfspraak = product.leningdeelgegevens.renteVariant === RentevariantOptions.Variabel ? 3 : 1;
  const rvpPeriodes = rentevarianten.find(rv => rv.renteafspraak === renteAfspraak)?.renteperiodes;

  let result = rvpPeriodes?.find(
    rp => rp.rentevastAantalMaanden === Number(product.leningdeelgegevens.rentevastPeriodeJaar) * 12
  );
  if (!result) {
    result = rvpPeriodes?.find(
      rp => rp.rentevastAantalMaanden === Number(product.leningdeelgegevens.rentevastPeriodeJaar) * 12
    );
  }
  if (!result && rvpPeriodes && rvpPeriodes.length > 0) {
    result = rvpPeriodes[0];
  }
  const rvp = result?.rentevastAantalMaanden;
  const rentebedenktijdMaanden = result?.rentebedenktijdInMaanden;
  product.leningdeelgegevens.rentevastPeriodeJaar = !!rvp ? rvp / 12 : null;
  product.leningdeelgegevens.renteBedenktijdJaar = rentebedenktijdMaanden ? rentebedenktijdMaanden / 12 : null;
};

export const bepaalWaarschuwing = (
  product: HypotheekType,
  rentevarianten: RentevariantMetRenteperiodes[],
  nhg: boolean | null
): void => {
  const warningMessage = getHypotheekTextResources(
    nhg ? "renteVastPeriodeWaarschuwing" : "renteVastPeriodeWaarschuwingExtended"
  );
  if (!!rentevarianten && rentevarianten.length > 0) {
    let waarschuwingTonen = rentevarianten.find(rv => rv.omschrijving === product.leningdeelgegevens.renteVariant)
      ?.marktwaardeWaarschuwingTonen;

    if (typeof waarschuwingTonen !== "boolean") {
      waarschuwingTonen = !!rentevarianten.find(() => true)?.marktwaardeWaarschuwingTonen;
    }

    if (product.marktwaardeWaarschuwingTonen !== waarschuwingTonen) {
      product.leningdeelgegevens.renteVariantenWaarschuwingText = warningMessage;
      product.marktwaardeWaarschuwingTonen = waarschuwingTonen;
    }
  } else {
    /* istanbul ignore else */
    if (
      product.leningdeelgegevens.renteVariantenWaarschuwingText !== warningMessage ||
      !product.marktwaardeWaarschuwingTonen
    ) {
      product.leningdeelgegevens.renteVariantenWaarschuwingText = warningMessage;
      product.marktwaardeWaarschuwingTonen = true;
    }
  }
};

export const bepaalRentepercentage = (
  product: HypotheekType,
  hypotheekrente: RentepercentageResultaat | null
): void => {
  const rentepercentage = product.leningdeelgegevens?.rentePercentage;
  if (hasValue(rentepercentage)) {
    if (hypotheekrente) {
      const berekendRentePc = hypotheekrente.rentePercentage ?? 0;
      rentepercentage.berekenen = rentepercentage.berekenen || true;
      rentepercentage.bedrag = berekendRentePc;
      rentepercentage.berekendBedrag = berekendRentePc;
    }
  }
};

export const bepaalRentepercentageIng = async <T>(
  klantdossiersFormsOrigin: string,
  draft: HypothekenState,
  context: Readonly<AsyncContext>,
  fetchData: <R, B = undefined>(params: ISWFetchDataParams<T, B>) => Promise<R>
): Promise<void> => {
  const pricetoolCalls: indexedFetchCall<IngPriceOutput>[] = [];
  const { voorstelId, ingHdnData } = context;

  draft.producten.forEach((prod, index) => {
    /* istanbul ignore next reason: this check has already been done is only to filter out the existing mortgages */
    if (prod.partijCode !== partijcodeING) return;

    if (
      typeof prod.leningdeelgegevens.rentePercentage?.bedrag === "number" &&
      !prod.leningdeelgegevens.rentePercentage.berekenen
    )
      return;

    const url = `${klantdossiersFormsOrigin}/PostPricetoolResult?voorstelId=${voorstelId}`;

    pricetoolCalls.push({
      call: fetchData({ url, method: "POST", body: mapIngPriceToolInput({ ingHdnData })(draft) }),
      index
    });
  });

  const fetchedPriceToolRentes = await Promise.all(pricetoolCalls.map(pc => pc.call));
  fetchedPriceToolRentes.forEach((pricetoolResponse, index) => {
    if (!pricetoolResponse || !pricetoolResponse.hypotheek) return;

    const renteGegevens = pricetoolResponse.hypotheek?.leningdelenResult?.shift();
    const rente = renteGegevens
      ? parseFloat(renteGegevens.standaardRente?.replace(",", ".") ?? /* istanbul ignore next */ "0") +
        parseFloat(renteGegevens.ltv?.replace(",", ".") ?? /* istanbul ignore next */ "0") +
        parseFloat(renteGegevens.betaalrekening?.replace(",", ".") ?? /* istanbul ignore next */ "0") +
        parseFloat(renteGegevens.dagrente?.replace(",", ".") ?? /* istanbul ignore next */ "0") +
        parseFloat(renteGegevens.loyaliteit?.replace(",", ".") ?? /* istanbul ignore next */ "0")
      : null;

    const rentePercentage = draft.producten[pricetoolCalls[index].index].leningdeelgegevens?.rentePercentage;
    if (hasValue(rentePercentage)) {
      rentePercentage.bedrag = rente;
      rentePercentage.berekenen = true;
      rentePercentage.berekendBedrag = rente;
    }
  });
};

export const hypotheekAsyncOpties = async (
  draft: HypothekenState,
  context: Readonly<AsyncContext>
): Promise<HypotheekOptieType | null> => {
  // indien hypotheekDetailData niet valide is, doen we niets
  if (!context.hypotheekDetailData?.isValid) {
    return null;
  }
  
  const selectedProduct = draft.producten[context.selected];
  const geldverstrekkerCode = selectedProduct.partijCode;
  const hypotheeklabelCode = selectedProduct.partijCode !== partijOnafhankelijk ? selectedProduct.labelCode : 0;
  const productenDl = context.hypotheekDetailData?.isValid && context.hypotheekDetailData?.producten;

  if (!productenDl || Object.keys(productenDl).length === 0) {
    return { hypotheekOpties: [] };
  }

  const data = Object.values(productenDl)[0];
  const hypotheekOptiesDl = data.hypotheekopties;

  // Get existing options for the current geldverstrekker and label
  const existingOptions = draft.hypotheekOptie.hypotheekOpties.filter(
    option => option.maatschappijCode === geldverstrekkerCode && 
             option.hypotheekLabelCode === hypotheeklabelCode
  );

  // Create map of existing options by code for quick lookup
  const existingOptionsMap = new Map(
    existingOptions.map(option => [option.code, option])
  );

  // Build new options while preserving existing selections
  const newHypotheekOpties = hypotheekOptiesDl?.reduce((acc, dlOptie) => {
    // Only add if maatschappijCode is correct and code doesn't exist in accumulator
    if (!acc.some(c => c.maatschappijCode === geldverstrekkerCode && c.code === dlOptie.code)) {
      const existingOption = existingOptionsMap.get(dlOptie.code || 0);
      
      acc.push({
        code: dlOptie.code || 0,
        // Preserve existing selection if present, otherwise use default/required status
        geselecteerd: existingOption?.geselecteerd ?? (dlOptie.verplicht || dlOptie.default || false),
        omschrijving: dlOptie.omschrijving,
        rentekortingPercentage: dlOptie.rentekortingPercentage,
        toelichting: dlOptie.toelichting,
        hypotheekLabelCode: hypotheeklabelCode,
        maatschappijCode: geldverstrekkerCode,
        default: dlOptie.default,
        verplicht: dlOptie.verplicht
      });
    }
    return acc;
  }, [] as any[]);

  // Preserve other existing options for different geldverstrekkers/labels
  const otherExistingOptions = draft.hypotheekOptie.hypotheekOpties.filter(
    option => option.maatschappijCode !== geldverstrekkerCode || 
             option.hypotheekLabelCode !== hypotheeklabelCode
  );

  return {
    hypotheekOpties: [
      ...otherExistingOptions,
      ...(newHypotheekOpties?.filter(
        c => c.maatschappijCode === geldverstrekkerCode && 
             c.hypotheekLabelCode === hypotheeklabelCode
      ) ?? [])
    ]
  };
};

export const mapPeriodiekeAflossingInput = createMapToDl(hypothekenBaseSchema)
  .with<number>()
  .to<PeriodiekeAflossingInput>({
    aflossingsVorm: (v, i) => v.producten[i].hypotheekVorm.aflossingsvorm,
    leningdeelBedrag: (v, i) => v.producten[i].leningdeelgegevens.leningdeelHoofdsom.bedrag,
    aanvangsdatum: (v, i) => mapLocalDateToString(v.producten[i].product.ingangsdatum) ?? "",
    looptijdInMaanden: (v, i) => {
      const maandenTotaal = jaarMaandInMaanden(v.producten[i].product.looptijd);
      return maandenTotaal ? maandenTotaal : null;
    },
    opgaveDatum: (v, i) =>
      mapLocalDateToString(v.producten[i].leningdeelgegevens.datumOpgave) ??
      mapLocalDateToString(v.producten[i].product.ingangsdatum),
    rentePercentage: (v, i) => v.producten[i].leningdeelgegevens.rentePercentage?.bedrag ?? 100
  });

export const mapPremieBerekeningInput = createMapToDl(hypothekenBaseSchema)
  .with<number>()
  .to<VermogensrekeningInput>({
    soortOpbouwBerekening: () => VermogensrekeningInputSoortOpbouwBerekening.Spaar,
    doelkapitaal: (v, i) =>
      (v.producten[i].verzekerde.verzekerde === AanvragerKeuze.Aanvrager2
        ? afronden(v.producten[i].kapitaalopbouw?.doelkapitaal2Bedrag || null, 0) || null
        : afronden(v.producten[i].kapitaalopbouw?.doelkapitaal1Bedrag || null, 0)) || null,
    garantiekapitaal: (v, i) =>
      (v.producten[i].verzekerde.verzekerde === AanvragerKeuze.Aanvrager2
        ? afronden(v.producten[i].kapitaalopbouw?.doelkapitaal2Bedrag || null, 0) || null
        : afronden(v.producten[i].kapitaalopbouw?.doelkapitaal1Bedrag || null, 0)) || null,
    rendement: (v, i) =>
      (v.producten[i].verzekerde.verzekerde === AanvragerKeuze.Aanvrager2
        ? v.producten[i].kapitaalopbouw?.doelkapitaal2Percentage
        : v.producten[i].kapitaalopbouw?.doelkapitaal1Percentage) || 0,
    garantierendement: (v, i) =>
      (v.producten[i].verzekerde.verzekerde === AanvragerKeuze.Aanvrager2
        ? v.producten[i].kapitaalopbouw?.doelkapitaal2Percentage
        : v.producten[i].kapitaalopbouw?.doelkapitaal1Percentage) || 0,
    looptijdInMaanden: (v, i) => {
      const product = v.producten[i];
      /* istanbul ignore else */
      if (product && product.premieGegevens) {
        return jaarMaandInMaanden(product.premieGegevens.looptijd);
      }
      return 0;
    },
    eersteInleg: (v, i) => v.producten[i].premieGegevens?.aanvangExtraPremieStortingenBedrag || 0,
    hoogLaagLooptijdInMaanden: (v, i) => {
      const product = v.producten[i];
      /* istanbul ignore else */
      if (product && product.premieGegevens) {
        return jaarMaandInMaanden(product.premieGegevens.hoogLaagLooptijd);
      }
      return 0;
    },
    hoogLaagInlegHoog: (v, i) => v.producten[i].premieGegevens?.spaarPremieHoog || 0,
    garantiepercentageInleg: (v, i) => 100,
    inleg: (v, i) => {
      const product = v.producten[i];
      let looptijdInMaanden = null;
      /* istanbul ignore else */
      if (product && product.premieGegevens) {
        looptijdInMaanden = jaarMaandInMaanden(product.premieGegevens.looptijd);
      }
      return {
        looptijdInMaanden: looptijdInMaanden || 0,
        termijn: v.producten[i].premieGegevens?.betalingstermijn as PeriodiekBedragInputTermijn,
        bedrag: 0
      };
    },
    onttrekking: (v, i) => {
      const product = v.producten[i];
      let looptijdInMaanden = null;
      /* istanbul ignore else */
      if (product && product.premieGegevens) {
        looptijdInMaanden = jaarMaandInMaanden(product.premieGegevens.looptijd);
      }
      return {
        looptijdInMaanden: looptijdInMaanden || 0,
        termijn: v.producten[i].premieGegevens?.betalingstermijn as PeriodiekeOnttrekkingInputTermijn,
        bedrag: 0,
        aanvangsmaand: null // TODO
      };
    },
    extraInleggen: (v, i) => {
      return [
        //TODO
        {
          jaar: 1,
          bedrag: 0
        }
      ];
    },
    extraOnttrekkingen: (v, i) => {
      return [
        //TODO
        {
          jaar: 1,
          bedrag: 0
        }
      ];
    },
    aankoopkostenPercentage: () => null, //TODO
    bekeerskostenPercentage: () => null, //Todo
    verkoopkostenPercentage: () => null, //Todo
    ingangsdatum: (v, i) => mapLocalDateToString(v.producten[i].product.ingangsdatum),
    opgebouwdewaarde: (v, i) => v.producten[i].fiscaleRegeling?.ingebrachteWaardeBedrag || /* istanbul ignore next */ null,
    opgebouwdewaardedatum: (v, i) => mapLocalDateToString(v.producten[i].product.ingangsdatum)
  });
