import { useRequestInit, useFeature, Notificaties2Context } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement, useCallback, useContext, useEffect, useRef, useState } from "react";
import { AflossingsVormType } from "../../.generated/forms/formstypes";
import {
  HypotheekvergelijkerStatusOptions,
  UitslagOptions
} from "../../.generated/hypotheek-vergelijker/hypotheek-vergelijkertypes";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { HypotheekvergelijkerInput } from "../../.generated/foundation/foundationtypes";
import { OrganisatieEx } from "../../.generated/licenties/licentiestypes";
import UserDetailsContext from "../../shared/user-details/user-details-context";
import { HypotheekResultaatType, HypotheekVergelijkenModalType } from "./hypotheek-vergelijken-types";
import classes from "./hypotheek-vergelijken.module.scss";
import { HypotheekVergelijkerResultatenDataGrid } from "./hypotheek-vergelijker-data-grid/hypotheek-vergelijker-resultaat-data-grid";
import {
  getHypotheekVergelijkerQueue,
  initHypotheekVergelijkerQueue,
  organisatieDataApi
} from "./infra/hypotheek-vergelijken-vergelijker-api";
import { mapHypotheekVergelijkerUiToDl } from "./infra/map-hypotheek-genereer-leningdelen-ui-2-dl";
import { mapHypotheekVergelijkerUiToDl as mapHypotheekVergelijkerUiToDl2 } from "./infra/map-hypotheek-vergelijker-input";

export const Stap3 = (): ReactElement => {
  const featureHypotheekvergelijkerViaServiceBus = useFeature("FeatureHypotheekvergelijker");
  const featureNotificatie2 = useFeature("FeatureNotificaties2");

  const { userDetails } = useContext(UserDetailsContext);
  const usersOrganisatieId = userDetails.organisatieId ? userDetails.organisatieId : null;
  const { values, setFieldValue } = useFormikContext<HypotheekVergelijkenModalType>();
  const { settings, params, user } = useRequestInit<{ vestiging: string; adviesdossier: string; voorstel: string }>();
  const [fetching, setFetching] = useState(false);
  const [fetchComplete, setFetchComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const initialValues = useRef<typeof values>(values);
  const prevResults = useRef<HypotheekResultaatType[]>([]);
  const timer = useRef<NodeJS.Timer | null>(null);
  const queueAbort = useRef(new AbortController());
  const fetchAbort = useRef(new AbortController());
  const [initialCallCalled, setInitialCallCalled] = useState(false);
  const { subscribeCorrelationId, connectionSignalR } = useContext(Notificaties2Context);
  const [requestedCorrelationId, setRequestedCorrelationId] = useState<string | null>(null);
  const resultaten = useRef<HypotheekResultaatType[]>([]);

  /* istanbul ignore next */
  const sorteerBinnengekomenResultaten = (a: HypotheekResultaatType, b: HypotheekResultaatType): number => {
    const maxHypInterneSorteringen = (): number => {
      switch (true) {
        case a.UitslagVoorwaarden === b.UitslagVoorwaarden:
          return a.NettoMaandlastAanvangBedrag - b.NettoMaandlastAanvangBedrag;
        case a.UitslagVoorwaarden === UitslagOptions.Groen:
          return -1;
        case b.UitslagVoorwaarden === UitslagOptions.Groen:
          return 1;
        case a.UitslagVoorwaarden === UitslagOptions.Rood:
          return 1;
        case b.UitslagVoorwaarden === UitslagOptions.Rood:
          return -1;
        default:
          return a.NettoMaandlastAanvangBedrag - b.NettoMaandlastAanvangBedrag;
      }
    };

    switch (true) {
      case a.UitslagMaximaleHypotheek === b.UitslagMaximaleHypotheek:
        return maxHypInterneSorteringen();
      case a.UitslagMaximaleHypotheek === UitslagOptions.Groen:
        return -1;
      case b.UitslagMaximaleHypotheek === UitslagOptions.Groen:
        return 1;
      case a.UitslagMaximaleHypotheek === UitslagOptions.Rood:
        return 1;
      case b.UitslagMaximaleHypotheek === UitslagOptions.Rood:
        return -1;
      default:
        return 0;
    }
  };

  const setTimer = (queueId: string, selectedResult?: boolean): void => {
    /* istanbul ignore else */
    if (!featureHypotheekvergelijkerViaServiceBus) {
      setFetching(true);
      timer.current = setTimeout(async (): Promise<void> => {
        /* istanbul ignore next */ if (!user) return;
        const newController = new AbortController();
        fetchAbort.current.abort();
        fetchAbort.current = newController;

        const res = await getHypotheekVergelijkerQueue(user, settings, params.vestiging, queueId, newController.signal);

        /* istanbul ignore next */
        if (!res || !res.resultaten) throw Error("no data received");
        if (!res.resultaten.length && res.status === HypotheekvergelijkerStatusOptions.Bezig) {
          setTimer(queueId);
          return;
        }

        const currResults = res.resultaten
          .filter(r => !!r)
          .map(
            (r): HypotheekResultaatType => {
              const hypotheekvorm = r.leningdelen?.find(_ => true)?.hypotheekvorm;

              return {
                AutomatischeRentedalingDirect: r.automatischeRentedalingDirect ?? true,
                AutomatischeRentedalingNaRvp: r.automatischeRentedalingNaRvp ?? true,
                BrutoMaandlastAanvangBedrag: r.brutoMaandlastAanvangBedrag ?? 0,
                IndicatieMeerLenenBedrag: r.indicatieMeerLenenBedrag ?? 0,
                MaatschappijCode: r.maatschappijCode ?? "",
                MaatschappijNaam: r.maatschappijNaam ?? "",
                leningdelen:
                  r.leningdelen?.map(
                    /* istanbul ignore next */ ld => ({
                      aflossingsvorm: ld.hypotheekvorm?.aflossingsvorm ?? AflossingsVormType.Annuïteit,
                      code: ld.hypotheekvorm?.code ?? 1,
                      omschrijving: ld.hypotheekvorm?.omschrijving ?? "",
                      isStartersLening: ld.hypotheekvorm?.starterslening ?? false,
                      isRestschuldLening: ld.hypotheekvorm?.restschuldFinanciering ?? false,
                      renteboxCode: ld.hypotheekvorm?.renteboxCode ?? 0
                    })
                  ) ?? null,
                MaximaleHypotheekBedrag: r.maximaleHypotheekBedrag ?? 0,
                MaximaleHypotheekNhgBedrag: r.maximaleHypotheekNhgBedrag ?? 0,
                MaximaleHypotheekOnderpandBedrag: r.maximaleHypotheekOnderpandBedrag ?? 0,
                NettoLastTotaalBedrag: r.nettoLastTotaalBedrag ?? 0,
                hypotheekvormCode: hypotheekvorm?.code ?? 0,
                NettoMaandlastAanvangBedrag: r.nettoMaandlastAanvangBedrag ?? 0,
                ProductCode: r.productCode ?? 0,
                Productnaam: r.productnaam ?? "",
                ToetsrentePercentage: r.toetsrentePercentage ?? 0,
                hypotheekomschrijving: hypotheekvorm?.omschrijving ?? "",
                aflossingsVorm: hypotheekvorm?.aflossingsvorm ?? AflossingsVormType.Annuïteit,
                UitslagMaximaleHypotheek: r.uitslagMaximaleHypotheek ?? UitslagOptions.Rood,
                UitslagVoorwaarden: r.uitslagVoorwaarden ?? UitslagOptions.Rood,
                VerplichteAflossingBedrag: r.verplichteAflossingBedrag ?? 0,
                VoorwaardenToelichtingregels: r.voorwaardenToelichtingregels?.map(x => x.toelichting ?? "") ?? [],
                WerkelijkRentePercentage: r.werkelijkRentePercentage ?? 0
              };
            }
          )
          .concat(prevResults.current);

        const sortedResultaten = currResults.sort(sorteerBinnengekomenResultaten);

        if (sortedResultaten.length && !selectedResult) {
          setFieldValue(
            "selectedResultaat",
            sortedResultaten.find(_ => true)
          );
        }

        setFieldValue("resultaten", sortedResultaten);
        prevResults.current = sortedResultaten;

        if (res.status === HypotheekvergelijkerStatusOptions.Bezig) {
          setTimer(queueId, !!sortedResultaten.length);
          return;
        }

        setFetching(false);
        setFetchComplete(true);
      }, 2500);
    }
  };

  /* istanbul ignore next */
  const commandRequest = useCallback(
    (correlationId: string): RequestInit => {
      setRequestedCorrelationId(correlationId);
      resultaten.current = [];
      const HypotheekvergelijkerInput: HypotheekvergelijkerInput = mapHypotheekVergelijkerUiToDl2({
        adviesdossierId: params.adviesdossier,
        voorstelId: params.voorstel,
        hypotheekvergelijkenModal: initialValues.current,
        correlationId
      });
      return {
        headers: {
          authorization: `${user?.token_type} ${user?.access_token}`,
          "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
          "Content-Type": "application/json",
          vestigingId: params.vestiging
        },
        method: "POST",
        body: JSON.stringify(HypotheekvergelijkerInput)
      };
    },
    [params.adviesdossier, params.voorstel, params.vestiging, settings, user]
  );

  const getVergelijkerResults = async (): Promise<void> => {
    if (featureHypotheekvergelijkerViaServiceBus && featureNotificatie2) {
      const url = `${settings.foundationOrigin}/Hypotheekvergelijker/bus/Hypotheekvergelijker`;
      const result = await subscribeCorrelationId(url, commandRequest, () => {
        setFetchComplete(true);
      });
      if (!result) {
        setFetchComplete(true);
      }
    } else {
      if (!user) return;
      setErrorMessage(null);
      const newController = new AbortController();
      queueAbort.current.abort();
      queueAbort.current = newController;
      setFetchComplete(false);
      setFetching(true);

      const org: OrganisatieEx = await organisatieDataApi(settings, user, usersOrganisatieId);

      const res = await initHypotheekVergelijkerQueue(
        user,
        settings,
        params.vestiging,
        params.voorstel,
        mapHypotheekVergelijkerUiToDl(initialValues.current),
        newController.signal
      );

      if (typeof res === "string") {
        setErrorMessage(res);
        return;
      }

      /* istanbul ignore next */
      if (!res || !org || !res.queueId || typeof res.aantalResultaten !== "number") throw Error("no data received");

      setFieldValue("verwachteTotaal", res.aantalResultaten);
      setFieldValue("organisatieData", org);
      setTimer(res.queueId, true);
    }
  };

  const init = (): void => {
    /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
    getVergelijkerResults();
  };

  const cleanup = useCallback((): void => {
    if (!featureHypotheekvergelijkerViaServiceBus) {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      queueAbort.current.abort();
      fetchAbort.current.abort();
      setFieldValue("resultaten", []);
    }
  }, [setFieldValue, featureHypotheekvergelijkerViaServiceBus]);

  useEffect((): typeof cleanup => cleanup, [cleanup]);

  /* istanbul ignore next */
  const mapResultaat = (r: any): HypotheekResultaatType => {
    // TODO: Servicebus moet ook string enum values teruggeven (dan is deze mapping niet meer nodig)
    const mapUitslagOptions = (uitslag: number): UitslagOptions => {
      switch (uitslag) {
        case 1:
          return UitslagOptions.Groen;
        case 2:
          return UitslagOptions.Oranje;
        case 3:
          return UitslagOptions.Rood;
        default:
          return UitslagOptions.Wit;
      }
    };

    function mapIntToAflossingsVormType(value: number): AflossingsVormType {
      switch (value) {
        case 0:
          return AflossingsVormType.Geen;
        case 1:
          return AflossingsVormType.Annuïteit;
        case 2:
          return AflossingsVormType.Lineair;
        case 3:
          return AflossingsVormType.Levensverzekering;
        case 4:
          return AflossingsVormType.Spaar;
        case 5:
          return AflossingsVormType.Aflosvrij;
        case 9:
          return AflossingsVormType.Krediet;
        case 10:
          return AflossingsVormType.Belegging;
        case 11:
          return AflossingsVormType.Hybride;
        case 12:
          return AflossingsVormType.UnitLinked;
        case 13:
          return AflossingsVormType.Spaarrekening;
        case 20:
          return AflossingsVormType.AnnuïteitUitgesteld;
        case 21:
          return AflossingsVormType.AnnuïteitBlok;
        case 22:
          return AflossingsVormType.KredietNoPay;
        default:
          return AflossingsVormType.Annuïteit;
      }
    }
    const hypotheekvorm = r.Leningdelen?.find((_: any) => true)?.Hypotheekvorm;
    const uitslagMaximaleHypotheek: UitslagOptions = mapUitslagOptions(r.UitslagMaximaleHypotheek);
    const uitslagVoorwaarden: UitslagOptions = mapUitslagOptions(r.UitslagVoorwaarden);

    return {
      AutomatischeRentedalingDirect: r.AutomatischeRentedalingDirect ?? true,
      AutomatischeRentedalingNaRvp: r.AutomatischeRentedalingNaRvp ?? true,
      BrutoMaandlastAanvangBedrag: r.BrutoMaandlastAanvangBedrag ?? 0,
      IndicatieMeerLenenBedrag: r.IndicatieMeerLenenBedrag ?? 0,
      MaatschappijCode: r.MaatschappijCode ?? "",
      MaatschappijNaam: r.MaatschappijNaam ?? "",
      leningdelen:
        r.Leningdelen?.map(
          /* istanbul ignore next */ (ld: any) => ({
            aflossingsvorm: mapIntToAflossingsVormType(ld.Hypotheekvorm?.Aflossingsvorm),
            code: ld.Hypotheekvorm?.Code ?? 1,
            omschrijving: ld.Hypotheekvorm?.Omschrijving ?? "",
            isStartersLening: ld.Hypotheekvorm?.Starterslening ?? false,
            isRestschuldLening: ld.Hypotheekvorm?.RestschuldFinanciering ?? false,
            renteboxCode: ld.Hypotheekvorm?.RenteboxCode ?? 0
          })
        ) ?? null,
      MaximaleHypotheekBedrag: r.MaximaleHypotheekBedrag ?? 0,
      MaximaleHypotheekNhgBedrag: r.MaximaleHypotheekNhgBedrag ?? 0,
      MaximaleHypotheekOnderpandBedrag: r.MaximaleHypotheekOnderpandBedrag ?? 0,
      NettoLastTotaalBedrag: r.NettoLastTotaalBedrag ?? 0,
      hypotheekvormCode: hypotheekvorm?.Code ?? 0,
      NettoMaandlastAanvangBedrag: r.NettoMaandlastAanvangBedrag ?? 0,
      ProductCode: r.ProductCode ?? 0,
      Productnaam: r.Productnaam ?? "",
      ToetsrentePercentage: r.ToetsrentePercentage ?? 0,
      hypotheekomschrijving: hypotheekvorm?.omschrijving ?? "",
      aflossingsVorm: hypotheekvorm?.aflossingsvorm ?? AflossingsVormType.Annuïteit,
      UitslagMaximaleHypotheek: uitslagMaximaleHypotheek,
      UitslagVoorwaarden: uitslagVoorwaarden,
      VerplichteAflossingBedrag: r.VerplichteAflossingBedrag ?? 0,
      VoorwaardenToelichtingregels: r.VoorwaardenToelichtingregels?.map((x: any) => x.Toelichting ?? "") ?? [],
      WerkelijkRentePercentage: r.WerkelijkRentePercentage ?? 0
    };
  };

  useEffect(
    /* istanbul ignore next */
    (): void => {
      if (featureHypotheekvergelijkerViaServiceBus) {
        connectionSignalR?.on("nieuwe-hypotheekvergelijker-partial", (data: any) => {
          const parsedData: any = JSON.parse(data);
          const mappedData = mapResultaat(parsedData.HypotheeklabelResult);
          if (parsedData.correlationId === requestedCorrelationId) {
            resultaten.current = [...resultaten.current, mappedData];
            setFieldValue("resultaten", resultaten.current.sort(sorteerBinnengekomenResultaten));
          }
        });
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [requestedCorrelationId]
  );

  if (!initialCallCalled) {
    init();
    setInitialCallCalled(true);
  }

  return (
    <div className={"mt-5"}>
      {errorMessage && <span className={classes.errorMessage}>{errorMessage}</span>}

      {!fetchComplete && !errorMessage && (
        <div className={"button-container"}>
          <button
            data-testid="btn-fetcher"
            type="button"
            id="fetch-button"
            className={`btn btn-primary ${classes.transition} ${fetching && classes.active} ${classes.btn_loader}`}
          >
            <div className={classes.loader}></div>
            <div className={classes.display_text}>
              {values.resultaten.length}
              {!featureHypotheekvergelijkerViaServiceBus && ` / ${values.verwachteTotaal}`}
            </div>
          </button>
        </div>
      )}
      {(!!values.resultaten.length || featureHypotheekvergelijkerViaServiceBus) && (
        <HypotheekVergelijkerResultatenDataGrid />
      )}

      {!values.nhg && (
        <div className="mt-4 mb-5 pb-5">
          <span className={"d-block"}>
            Aan dit overzicht kunnen geen rechten worden ontleend. Dit overzicht is onder voorbehoud van goedkeuring
            door de maatschappij.
          </span>
          <span className={"d-block mt-2"}>
            * Rente daalt na de rentevastperiode automatisch bij lagere schuld-marktwaarde verhouding.
          </span>
          <span className={"d-block"}>** Rente daalt direct bij lagere schuld-marktwaardeverhouding</span>
        </div>
      )}
    </div>
  );
};
